import React from "react"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import useStyles from "../styles/project"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/layouts/ProjectItem"
import Fade from "../components/Fade"
import { sort } from "../utils"
import { allContentfulProjects } from "../interface"

interface Data {
  allContentfulProjects: allContentfulProjects
}
interface Props {
  uri: string
  data: Data
}

const Projects: React.FC<Props> = props => {
  const { data } = props
  const c = useStyles()
  const projs = data.allContentfulProjects.nodes
  const recent = sort(
    projs.filter(p => p.recent),
    "order"
  )
  const old = sort(
    projs.filter(p => !p.recent),
    "order"
  )

  return (
    <Layout uri={props.uri}>
      <SEO title="Projects" />
      <Fade>
        <main className={c.main}>
          <Typography className={c.typography} variant="h4">
            Recent Projects
          </Typography>
          {recent.map(p => (
            <Project key={p.id} project={p} />
          ))}
          <Typography className={c.typography} variant="h4">
            Old Projects
          </Typography>
          {old.map(p => (
            <Project key={p.id} project={p} />
          ))}
        </main>
      </Fade>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query fetchContentProject {
    allContentfulProjects {
      nodes {
        id
        order
        title
        recent
        siteurl
        description
        stack
        img {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  }
`
