import React from "react"
import Img from "gatsby-image"
import Typography from "@material-ui/core/Typography"

import SkillImage from "../../components/SkillImage"
import useStyles from "../../styles/projectitem"
import { Project } from "../../interface"

const ProjectItem: React.FC<Props> = props => {
  const c = useStyles()
  const { description, title, siteurl, img, stack } = props.project

  return (
    <main className={c.main}>
      <section className={c.section}>
        <div className={c.image}>
          <a href={siteurl} target="_blank" rel="noopener">
            <Img fluid={img.fluid} />
          </a>
        </div>
      </section>
      <section className={c.section}>
        <Typography className={c.title} variant="h6">
          {title}
        </Typography>
        <div className={c.description}>{description}</div>
        <div className={c.skillImageContainer}>
          {stack &&
            stack.map(s => <SkillImage key={s} width="50px" name={s} />)}
        </div>
      </section>
    </main>
  )
}

export default ProjectItem

interface Props {
  project: Project
}
