import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: "2em 0"
    },
    typography: {
      transition: "all .3s ease",
      padding: ".2em 0",
      fontSize: "1.8rem",
      margin: "2rem 0 2rem",
      [theme.breakpoints.down(900)]: {
        maxWidth: "450px",
        margin: "2rem auto 2rem"
      },
      [theme.breakpoints.down(500)]: {
        fontSize: "1.5rem",
        margin: "1.5rem 0 1.5rem"
      }
    }
  })
)
