import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      transition: "all .3s ease",
      margin: ".5em 0 4em",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(900)]: {
        flexDirection: "column"
      }
    },
    section: {
      transition: "all .3s ease",
      width: "50%",
      [theme.breakpoints.down(900)]: {
        width: "100%",
        maxWidth: "450px"
      }
    },
    title: {
      transition: "all .3s ease",
      [theme.breakpoints.down(450)]: {
        fontSize: "1rem"
      }
    },
    description: {
      transition: "all .3s ease",
      [theme.breakpoints.down(450)]: {
        fontSize: ".8rem"
      }
    },
    skillImageContainer: {
      transition: "all .3s ease",
      display: "flex",
      overflowX: "auto",
      padding: ".5rem 0 .5rem",
      "&::-webkit-scrollbar": {
        height: "10px"
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        backgroundColor: "#F5F5F5",
        borderRadius: "15px"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        borderRadius: "15px"
      }
    },
    image: {
      minWidth: 100,
      width: "100%",
      border: `.5px solid ${theme.palette.secondary.main}`,
      borderRadius: 10,
      overflow: "hidden",
      maxWidth: 400,
      marginRight: "auto",
      transition: "all .3s ease",
      [theme.breakpoints.down(900)]: {
        marginBottom: "1em",
        maxWidth: 450
      }
    }
  })
)
