import React from "react"
import Image from "./image"

const SkillImage: React.FC<Props> = props => {
  const { width, name, custom } = props
  const imgName = name.replace(/\./g, " ").trim()
  return (
    <section style={{ textAlign: "center", margin: "0 .5em", ...custom }}>
      <div style={{ width, margin: "0 auto" }}>
        <Image name={name} />
      </div>
      <div>{imgName}</div>
    </section>
  )
}

interface Props {
  name: string
  width: string
  custom?: {}
}

export default SkillImage
